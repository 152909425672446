/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import PropTypes from "prop-types"
import * as fonts from "../../fonts"

const theme = {
  button: "#f654ae",
  red: "#f654ae",
  buttonBorderTransparent: "#f654ae",
  black: "#1b1b1b",
  grey: "#3A3A3A",
  lightgrey: "#E1E1E1",
  offWhite: "#EDEDED",
  maxWidth: "1200px",
  maxWidthSingle: "840px",
  maxWidthFront: "600px",
  innerPadding: "4rem 2rem",
  bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)",
}

const StyledPage = styled.div`
  background: white;
  color: ${props => props.theme.black};
`

const Content = styled.div`
  min-height: 82vh;
`
// prettier-ignore
const GlobalStyle = createGlobalStyle`
html {
box-sizing: border-box;
font-size: 10px;
}
*, *:before, *:after {
box-sizing: inherit;
}
@font-face {
    font-family: 'settle_down';
    font-style: normal;
    font-weight: normal;
    src: local('settle_down'), url('${fonts.SettleDownWebfont}') format('truetype');
  }
body {
padding: 0;
margin: 0;
font-size: 1.6rem;
line-height: 1.8;
font-family: 'Roboto', sans-serif;
font-weight: 400;
}
a {
text-decoration: none;
border-bottom: 1px solid #f2b306;
color: ${(props) => props.theme.black};
border-bottom: ${(props) => `2px solid ${props.theme.red}`};
}
 
h1 {
  font-size: 3.2rem;
  font-weight: 500;
  margin: 0.5rem 0 2rem;
  line-height: 1.2;
  @media (max-width: 800px) {
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
    }
}

h2,h3,h4,h5,h6 {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0.5rem 0 1.5rem;
  text-align: left;
  line-height: 1.2;
  @media (max-width: 800px) {
    font-size: 2rem;
    }
}
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <StyledPage>
        <Content>{children}</Content>
      </StyledPage>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
